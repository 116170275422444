import { Injectable } from '@angular/core';
import { Action, createPropertySelectors, State, StateContext, StateToken } from '@ngxs/store';

import { SetUi } from './config.actions';

export interface ConfigStateModel {
  ui: UiDto;
  skus: SkuDto[];
}

export interface UiColors {
  primary: string;
  secondary: string;
  warning: string;
}

export interface UiDto {
  id: string;
  name: string;
  colors: UiColors;
  logo: string;
  landingImage: string;
  favicon: string;
  reportIcon: string;
  seo: {
    title: string;
    description: string;
  };
  links?: UiLinks;
}

export interface UiLinks {
  app?: {
    privacy?: string;
    terms?: string;
  };
  guest?: {
    newAnalysis?: string;
    videoConsult?: string;
  };
  member?: {
    videoConsult?: string;
  };
}

export interface SkuDto {
  id: string;
  name: string;
  features: Record<string, boolean>;
}

export const CONFIG_STATE = new StateToken<ConfigStateModel>('CONFIG_STATE');

@State({
  name: CONFIG_STATE,
  defaults: null,
})
@Injectable()
export class ConfigState {

  public static slices = createPropertySelectors(CONFIG_STATE);
  public static ui = createPropertySelectors(ConfigState.slices.ui);

  @Action(SetUi)
  setConfig(
    { patchState }: StateContext<ConfigStateModel>,
    { ui }: SetUi,
  ) {
    patchState({ ui });
    document.documentElement.style.setProperty('--primary-color', ui.colors.primary);
    document.documentElement.style.setProperty('--secondary-color', ui.colors.secondary);
    document.documentElement.style.setProperty('--warning-color', ui.colors.warning);
    (document.querySelector('link[rel="icon"]') as HTMLLinkElement).href = ui.favicon;
    if (ui.seo) {
      document.querySelector('title')!.innerText = ui.seo.title;
      (document.querySelector('meta[name="description"]') as HTMLMetaElement).innerText = ui.seo.description;
    }
  }
}
